<template>
    <BT-Blade-Items
        addBladeName='supplier-price-tier'
        bladeName="supplier-price-tiers"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        clearBlades
        :headers="[
            { text: 'Tier Name', value: 'priceTierName', title: 1, searchable: true }]"
        navigation="supplier-price-tiers"
        title="Price Tiers"
        useServerPagination />
</template>

<script>
export default {
    name: 'Supplier-Price-Tiers-Blade',
    props: {
        bladesData: null
    }
}
</script>